import { useState } from "react";
import { Link } from "react-router-dom";
import galeri1 from "../../assets/galeri/42.png";
import galeri2 from "../../assets/galeri/43.png";
import galeri3 from "../../assets/galeri/44.png";
import galeri4 from "../../assets/galeri/45.png";
import galeri5 from "../../assets/galeri/46.png";
import galeri6 from "../../assets/galeri/47.png";
import galeri7 from "../../assets/galeri/48.png";
import galeri8 from "../../assets/galeri/49.png";
import galeri9 from "../../assets/galeri/50.png";
// import galeri1 from "../../assets/galeri/42.png";
import AOS from "aos";
import "aos/dist/aos.css";
import { Dialog } from "@headlessui/react";
import { FaBars, FaTimes } from "react-icons/fa";

const GaleriMain = () => {
  AOS.init();
  AOS.refresh();
  AOS.refreshHard();

  const [isTampil, setIsTampil] = useState(false);
  const [fotoTampil, setFotoTampil] = useState();

  function closeFoto() {
    setIsTampil(false);
    setFotoTampil();
  }

  function openFoto(foto) {
    setFotoTampil(foto);
    setIsTampil(true);
    console.log(isTampil, fotoTampil);
  }
  return (
    <>
      <div className="bg-dark-gray pt-20 pb-8 px-6 xl:pb-12 xl:pt-24">
        <div data-aos="fade-down" data-aos-duration="1500">
          <h1 className="text-center font-bold text-2xl font-Rubik xl:text-3xl">
            Galeri Pengerjaan Kami
          </h1>
          <div className="items-center justify-center mt-2 xl:mt-8 flex flex-col xl:grid xl:grid-cols-3 xl:gap-7 gap-4 xl:px-24">
            <div className="">
              <button
                className="transition ease-in-out duration-500 md:w-[500px] xl:w-full filter transform md:hover:scale-110"
                onClick={() => openFoto(galeri1)}
              >
                <img className="rounded-xl" src={galeri1} alt="rectangle"></img>
              </button>
            </div>
            <div className="">
              <button
                className="transition ease-in-out duration-500 md:w-[500px] xl:w-full filter transform md:hover:scale-110"
                onClick={() => openFoto(galeri2)}
              >
                <img className="rounded-xl" src={galeri2} alt="rectangle"></img>
              </button>
            </div>
            <div className="">
              <button
                className="transition ease-in-out duration-500 md:w-[500px] xl:w-full filter transform md:hover:scale-110"
                onClick={() => openFoto(galeri3)}
              >
                <img className="rounded-xl" src={galeri3} alt="rectangle"></img>
              </button>
            </div>
            <div className="">
              <button
                className="transition ease-in-out duration-500 md:w-[500px] xl:w-full filter transform md:hover:scale-110"
                onClick={() => openFoto(galeri4)}
              >
                <img className="rounded-xl" src={galeri4} alt="rectangle"></img>
              </button>
            </div>
            <div className="">
              <button
                className="transition ease-in-out duration-500 md:w-[500px] xl:w-full filter transform md:hover:scale-110"
                onClick={() => openFoto(galeri5)}
              >
                <img className="rounded-xl" src={galeri5} alt="rectangle"></img>
              </button>
            </div>

            <div className="">
              <button
                className="transition ease-in-out duration-500 md:w-[500px] xl:w-full filter transform md:hover:scale-110"
                onClick={() => openFoto(galeri6)}
              >
                <img className="rounded-xl" src={galeri6} alt="rectangle"></img>
              </button>
            </div>
            <div className="">
              <button
                className="transition ease-in-out duration-500 md:w-[500px] xl:w-full filter transform md:hover:scale-110"
                onClick={() => openFoto(galeri7)}
              >
                <img className="rounded-xl" src={galeri7} alt="rectangle"></img>
              </button>
            </div>
            <div className="">
              <button
                className="transition ease-in-out duration-500 md:w-[500px] xl:w-full filter transform md:hover:scale-110"
                onClick={() => openFoto(galeri8)}
              >
                <img className="rounded-xl" src={galeri8} alt="rectangle"></img>
              </button>
            </div>
            <div className="">
              <button
                className="transition ease-in-out duration-500 md:w-[500px] xl:w-full filter transform md:hover:scale-110"
                onClick={() => openFoto(galeri9)}
              >
                <img className="rounded-xl" src={galeri9} alt="rectangle"></img>
              </button>
            </div>
          </div>
          <Dialog open={isTampil} onClose={closeFoto}>
            <Dialog.Overlay />
            <div className="fixed px-2 py-4 mt-10 xl:top-0 top-12 inset-0 rounded-xl overflow-y-scroll overflow-x-hidden">
              <div
                className="fixed top-0 left-0 bg-gray-900 bg-opacity-70 w-full h-full"
                onClick={closeFoto}
              />
              <div className="relative xl:w-1/2 w-9/10 md:w-[80%] xl:mt-8 h-full m-auto">
                {fotoTampil && (
                  <div
                    className={`m-auto p-1 relative rounded-xl shadow green-900`}
                  >
                    <button
                      className="md:text-4xl text-3xl text-dark-blue absolute md:-right-6 -right-3 px-2 md:-mt-5 -mt-4 rounded-full overflow-hidden z-40"
                      onClick={closeFoto}
                    >
                      <FaTimes className="text-white" />
                    </button>
                    <img
                      className="rounded-xl mx-auto w-full my-4"
                      src={fotoTampil}
                      alt=""
                    />
                  </div>
                )}
              </div>
            </div>
          </Dialog>
        </div>
      </div>
    </>
  );
};

export default GaleriMain;
