import { useState } from "react";
import { Link } from "react-router-dom";
import tips1 from "../../assets/tips/Info.png";
import tips2 from "../../assets/tips/Info (1).png";
import tips3 from "../../assets/tips/Info 2.png";
import AOS from "aos";
import "aos/dist/aos.css";
import { Dialog } from "@headlessui/react";
import { FaBars, FaTimes } from "react-icons/fa";

const TipsMain = () => {
  AOS.init();
  AOS.refresh();
  AOS.refreshHard();

  const [isTampil, setIsTampil] = useState(false);
  const [fotoTampil, setFotoTampil] = useState();

  function closeFoto() {
    setIsTampil(false);
    setFotoTampil();
  }

  function openFoto(foto) {
    setFotoTampil(foto);
    setIsTampil(true);
    console.log(isTampil, fotoTampil);
  }
  return (
    <>
      <div className="bg-light-gray pt-20 pb-8 px-6 xl:pb-12 xl:pt-24">
        <div data-aos="fade-down" data-aos-duration="1500">
          <h1 className="text-center font-Rubik font-bold text-2xl xl:text-3xl">
            Tips Sepatu
          </h1>
          <div className="items-center justify-center mt-2 xl:mt-8 flex flex-col xl:flex-row xl:gap-7 gap-4 xl:px-24">
            <div className="">
              <button
                className="transition ease-in-out duration-500 md:w-[500px] xl:w-full filter transform md:hover:scale-110"
                onClick={() => openFoto(tips1)}
              >
                <img className="rounded-xl" src={tips1} alt="rectangle"></img>
              </button>
            </div>
            <div className="">
              <button
                className="transition ease-in-out duration-500 md:w-[500px] xl:w-full filter transform md:hover:scale-110"
                onClick={() => openFoto(tips2)}
              >
                <img className="rounded-xl" src={tips2} alt="rectangle"></img>
              </button>
            </div>
            <div className="col-span-2">
              <button
                className="transition ease-in-out duration-500 md:w-[500px] xl:w-full filter transform md:hover:scale-110"
                onClick={() => openFoto(tips3)}
              >
                <img className="rounded-xl" src={tips3} alt="rectangle"></img>
              </button>
            </div>
          </div>
          <Dialog open={isTampil} onClose={closeFoto}>
            <Dialog.Overlay />
            <div className="fixed px-2 py-4 mt-10 xl:top-0 top-12 inset-0 rounded-xl overflow-y-scroll overflow-x-hidden">
              <div
                className="fixed top-0 left-0 bg-gray-900 bg-opacity-70 w-full h-full"
                onClick={closeFoto}
              />
              <div className="relative xl:w-1/2 w-9/10 md:w-[80%] xl:mt-8 h-full m-auto">
                {fotoTampil && (
                  <div
                    className={`m-auto p-1 relative rounded-xl shadow green-900`}
                  >
                    <button
                      className="md:text-4xl text-3xl text-dark-blue absolute md:-right-6 -right-3 px-2 md:-mt-5 -mt-4 rounded-full overflow-hidden z-40"
                      onClick={closeFoto}
                    >
                      <FaTimes className="text-white" />
                    </button>
                    <img
                      className="rounded-xl mx-auto w-full my-4"
                      src={fotoTampil}
                      alt=""
                    />
                  </div>
                )}
              </div>
            </div>
          </Dialog>
        </div>
      </div>
    </>
  );
};

export default TipsMain;
