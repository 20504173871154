import { Link, NavLink } from "react-router-dom";
import React, { useState } from "react";
import logo from "../assets/logo.jpg";
import "./navbar.css";
import { CSSTransition } from "react-transition-group";
import { Divide as Hamburger } from "hamburger-react";
import { FaBars, FaTimes } from "react-icons/fa";

const Navbar = () => {
  // setting mobile nav
  const [click, setClick] = useState(false);
  const handleClick = () => setClick(!click);

  // change nav color when scroll
  const [color, setColor] = useState(false);
  const changeColor = () => {
    if (window.scrollY >= 70) {
      setColor(true);
    } else {
      setColor(false);
    }
  };

  window.addEventListener("scroll", changeColor);

  // close menu on click
  const closeMenu = () => setClick(false);

  return (
    <div className={color ? "header header-bg" : "header font-header"}>
      <nav className="navbar">
        <Link to="" className="logo">
          <img
            onClick={() => {
              window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
            }}
            src={logo}
            className=""
            alt="logo"
          />
        </Link>
        <div className="hamburger" onClick={handleClick}>
          {click ? (
            <FaTimes
              size={30}
              className={color ? "color-nav-mobile" : "font-header-2"}
            />
          ) : (
            <FaBars
              size={30}
              className={color ? "color-nav-mobile" : "font-header-2"}
            />
          )}
        </div>
        <ul className={click ? "nav-menu active" : "nav-menu"}>
          <li className="nav-item">
            <Link
              to=""
              onClick={() => {
                window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
                closeMenu();
              }}
            >
              Beranda
            </Link>
          </li>
          <li className="nav-item">
            <Link
              to="/layanan"
              onClick={() => {
                window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
                closeMenu();
              }}
            >
              Layanan
            </Link>
          </li>
          <li className="nav-item">
            <Link
              to="/tips"
              onClick={() => {
                window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
                closeMenu();
              }}
            >
              Tips
            </Link>
          </li>
          <li className="nav-item">
            <Link
              to="/galeri"
              onClick={() => {
                window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
                closeMenu();
              }}
            >
              Galeri
            </Link>
          </li>
          {/* <li className="nav-item">
            <Link
              to="/kontak"
              onClick={() => {
                window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
                closeMenu();
              }}
            >
              Kontak
            </Link>
          </li> */}
        </ul>
      </nav>
    </div>
  );
};

export default Navbar;
