import React, { useEffect } from "react";
import { Helmet } from "react-helmet";

const GoogleAnalytics = ({ trackingId }) => {
  useEffect(() => {
    // Function to initialize Google Analytics
    window.dataLayer = window.dataLayer || [];
    function gtag() {
      window.dataLayer.push(arguments);
    }
    gtag("js", new Date());
    gtag("config", trackingId);
  }, [trackingId]);

  return (
    <Helmet>
      {/* Google Tag Manager script */}
      <script
        async
        src={`https://www.googletagmanager.com/gtag/js?id=${trackingId}`}
      ></script>
      {/* Custom script for Google Analytics */}
      <script>
        {`
          window.dataLayer = window.dataLayer || [];
          function gtag(){window.dataLayer.push(arguments);}
          gtag('js', new Date());
          gtag('config', '${trackingId}');
        `}
      </script>
    </Helmet>
  );
};

export default GoogleAnalytics;
