import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import deep_clean from "../../assets/home/Deep clean.jpg";
import leather from "../../assets/home/Leather.png";
import reglue from "../../assets/home/Reglue.jpg";
import reguler_clean from "../../assets/home/Reguler Clean.jpg";
import slipper from "../../assets/home/Slipper.png";
import unyellowing from "../../assets/home/Unyellowing.jpg";
import whitening from "../../assets/home/Whitening.png";
import AOS from "aos";
import "aos/dist/aos.css";

const Layanan = () => {
  var settings = {
    dots: true,
    arrows: false,
    infinite: true,
    autoplay: false,
    speed: 500,
    autoplaySpeed: 1500,
    slidesToShow: 4,
    slidesToScroll: 1,
    pauseOnHover: false,
    initialSlide: 0,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          infinite: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          // initialSlide: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };
  AOS.init();
  AOS.refresh();
  AOS.refreshHard();
  return (
    <>
      <div className="bg-dark-gray pt-12 pb-12">
        <div className="px-2 xl:px-12">
          <h1
            data-aos="fade-down"
            data-aos-duration="1500"
            data-aos-offset="500"
            className="text-center text-2xl xl:text-3xl font-bold font-Rubik"
          >
            Layanan Kami
          </h1>
          <div
            data-aos="fade-up"
            data-aos-duration="1500"
            data-aos-offset="500"
          >
            <Slider {...settings} className="cursor-pointer">
              <div className="px-4 py-4">
                <div className="transform duration-500 ease-in-out hover:scale-[110%]">
                  <p className="font-bold text-lg">Deep Clean</p>
                  <img
                    className="rounded-2xl"
                    src={deep_clean}
                    alt="rectangle"
                  ></img>
                </div>
              </div>
              <div className="px-4 py-4">
                <div className="transform duration-500 ease-in-out hover:scale-[110%]">
                  <p className="font-bold text-lg">Leather</p>
                  <img
                    className="rounded-2xl"
                    src={leather}
                    alt="rectangle"
                  ></img>
                </div>
              </div>
              <div className="px-4 py-4">
                <div className="transform duration-500 ease-in-out hover:scale-[110%]">
                  <p className="font-bold text-lg">Reglue</p>
                  <img
                    className="rounded-2xl"
                    src={reglue}
                    alt="rectangle"
                  ></img>
                </div>
              </div>
              <div className="px-4 py-4">
                <div className="transform duration-500 ease-in-out hover:scale-[110%]">
                  <p className="font-bold text-lg">Reguler Clean</p>
                  <img
                    className="rounded-2xl"
                    src={reguler_clean}
                    alt="rectangle"
                  ></img>
                </div>
              </div>
              <div className="px-4 py-4">
                <div className="transform duration-500 ease-in-out hover:scale-[110%]">
                  <p className="font-bold text-lg">Slipper</p>
                  <img
                    className="rounded-2xl"
                    src={slipper}
                    alt="rectangle"
                  ></img>
                </div>
              </div>
              <div className="px-4 py-4">
                <div className="transform duration-500 ease-in-out hover:scale-[110%]">
                  <p className="font-bold text-lg">Unyellowing</p>
                  <img
                    className="rounded-2xl"
                    src={unyellowing}
                    alt="rectangle"
                  ></img>
                </div>
              </div>
              <div className="px-4 py-4">
                <div className="transform duration-500 ease-in-out hover:scale-[110%]">
                  <p className="font-bold text-lg">Whitening</p>
                  <img
                    className="rounded-2xl"
                    src={whitening}
                    alt="rectangle"
                  ></img>
                </div>
              </div>
            </Slider>
          </div>
        </div>
      </div>
      {/* </div> */}
    </>
  );
};

export default Layanan;
