import { Link } from "react-router-dom";

import LayananHighlight from "../components/layanan/layanan_main";
import { HtmlHead } from "../components/HtmlHead";
import GoogleAnalytics from "../components/googleads";

export default function Layanan() {
  return (
    <>
      <HtmlHead
        title="Layanan"
        decription="[insert page description]"
        link="/layanan"
      />
      <main className="overflow-hidden">
        <GoogleAnalytics trackingId="G-EHY9VNJGZ8" />
        <LayananHighlight />
      </main>
    </>
  );
}
