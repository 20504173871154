import logo from "./logo.svg";
import "./App.css";
import { Routes, Route } from "react-router-dom";
import Navbar from "./components/navbar";
import Home from "./pages/Home";
import Layanan from "./pages/Layanan";
import Tips from "./pages/Tips";
import Galeri from "./pages/Galeri";
import Footer from "./components/footer";

function App() {
  return (
    <div className="App">
      <Navbar />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/layanan" element={<Layanan />} />
        <Route path="/tips" element={<Tips />} />
        <Route path="/galeri" element={<Galeri />} />
      </Routes>
      <Footer />
    </div>
  );
}

export default App;
