import antar_jemput from "../../assets/home/Gratis antar jemput.png";
import jangkauan_luas from "../../assets/home/Jangkauan Luas.png";
import harga_terjangkau from "../../assets/home/Harga Terjangkau.png";
import pelayanan_cepat from "../../assets/home/Pelayanan Cepat.png";
import kualitas_terjamin from "../../assets/home/Kualitas Terjamin.png";
import AOS from "aos";
import "aos/dist/aos.css";

const Value = () => {
  return (
    <>
      <div className="bg-light-gray px-6 xl:px-20 pt-12 xl:pb-12">
        <p
          className="font-bold text-2xl xl:text-3xl font-Rubik text-right"
          data-aos="fade-right"
          data-aos-duration="1500"
          data-aos-offset="800"
        >
          Apa yang Membuat <br /> Kami Berbeda
        </p>
        <div
          data-aos="fade-up"
          data-aos-duration="1500"
          data-aos-offset="900"
          className="text-left flex flex-col md:grid md:grid-cols-6 items-center justify-center xl:gap-10 xl:px-12"
        >
          <div className="md:col-span-2">
            <div className="flex justify-center items-center">
              <img className="w-3/4" src={antar_jemput} alt="" />
            </div>
            <h1 className="text-2xl font-bold">Gratis Antar Jemput</h1>
            <p className="text-left">
              GRATIS antar jemput dalam radius 5 km dari drop point
            </p>
          </div>
          <div className="md:col-span-2">
            <div className="flex justify-center items-center">
              <img className="w-3/4" src={jangkauan_luas} alt="" />
            </div>
            <h1 className="text-2xl font-bold">Jangkauan Luas</h1>
            <p className="text-left">
              Terdapat 6 drop point yang tersebar di area strategis
            </p>
          </div>
          <div className="md:col-span-2">
            <div className="flex justify-center items-center">
              <img className="w-3/4" src={harga_terjangkau} alt="" />
            </div>
            <h1 className="text-2xl font-bold">Harga Terjangkau</h1>
            <p className="text-left">
              Harga ramah di kantong, mulai dari Rp15.000
            </p>
          </div>
          <div className="md:col-start-2 md:col-span-2">
            <div className="flex justify-center items-center">
              <img className="w-3/4" src={pelayanan_cepat} alt="" />
            </div>
            <h1 className="text-2xl font-bold">Pelayanan Cepat</h1>
            <p className="text-left">Estimasi pengerjaan 2-3 hari</p>
          </div>
          <div className="md:col-span-2 md:col-start-4">
            <div className="flex justify-center items-center">
              <img className="w-3/4" src={kualitas_terjamin} alt="" />
            </div>
            <h1 className="text-2xl font-bold">Kualitas Terjamin</h1>
            <p className="text-left">Garansi kualitas hasil pengerjaan</p>
          </div>
        </div>
      </div>
    </>
  );
  // AOS.init();
  // AOS.refresh();
  // AOS.refreshHard();
};

export default Value;
