import deep_clean from "../../assets/home/Deep clean.jpg";
import leather from "../../assets/home/Leather.png";
import reglue from "../../assets/home/Reglue.jpg";
import reguler_clean from "../../assets/home/Reguler Clean.jpg";
import slipper from "../../assets/home/Slipper.png";
import unyellowing from "../../assets/home/Unyellowing.jpg";
import whitening from "../../assets/home/Whitening.png";
import AOS from "aos";
import "aos/dist/aos.css";

const LayananMain = () => {
  AOS.init();
  AOS.refresh();
  AOS.refreshHard();
  return (
    <>
      <div className="bg-dark-gray px-6 xl:px-20 pt-20 pb-8 xl:pb-12 xl:text-lg">
        <div data-aos="fade-down" data-aos-duration="1500">
          <p className="font-bold text-2xl font-Rubik xl:text-3xl text-center">
            Layanan Kami
          </p>
          <p className="pt-2 pb-4 text-base xl:text-lg">
            Kami menawarkan berbagai macam layanan untuk melakukan perawatan
            yang sesuai terhadap barang Anda.
          </p>
          <div className="text-justify flex flex-col md:grid md:grid-cols-6 gap-6 xl:gap-10 xl:px-12">
            <div className="md:col-span-2">
              <div className="flex justify-center items-center">
                <img className="w-full" src={deep_clean} alt="" />
              </div>
              <h1 className="text-2xl font-bold py-2">Deep Clean</h1>
              <p className="text-justify">
                Pencucian sepatu secara menyeluruh (outsole, midsole, upper,
                insole) dan tali sepatu. Direkomendasikan untuk model sepatu
                sneakers, sport, dan hiking.
              </p>
            </div>
            <div className="md:col-span-2">
              <div className="flex justify-center items-center">
                <img className="w-full" src={leather} alt="" />
              </div>
              <h1 className="text-2xl font-bold py-2">Leather Treatment</h1>
              <p className="text-justify">
                Perawatan untuk jenis sepatu berbahan kulit agar lebih
                mengkilap.
              </p>
            </div>
            <div className="md:col-span-2">
              <div className="flex justify-center items-center">
                <img className="w-full" src={reglue} alt="" />
              </div>
              <h1 className="text-2xl font-bold py-2">Reglue Treatment</h1>
              <p className="text-left">
                Perawatan untuk sol sepatu yang sudah membuka
              </p>
            </div>
            <div className="md:col-span-2">
              <div className="flex justify-center items-center">
                <img className="w-full" src={reguler_clean} alt="" />
              </div>
              <h1 className="text-2xl font-bold py-2">Reguler Clean</h1>
              <p className="text-justify">
                Pencucian bagian luar sepatu (outsole, midsole, upper).
                Direkomendasikan untuk sepatu tidak bertali seperti high heels,
                flat shoes, slip-on, wedges, dan sejenisnya.{" "}
              </p>
            </div>
            <div className="md:col-span-2">
              <div className="flex justify-center items-center">
                <img className="w-full" src={slipper} alt="" />
              </div>
              <h1 className="text-2xl font-bold py-2">Slipper Clean</h1>
              <p className="text-justify">
                Pencucian sandal secara menyeluruh. Direkomendasikan untuk
                berbagai jenis sandal.
              </p>
            </div>
            <div className="md:col-span-2">
              <div className="flex justify-center items-center">
                <img className="w-full" src={unyellowing} alt="" />
              </div>
              <h1 className="text-2xl font-bold py-2">Unyellowing Treatment</h1>
              <p className="text-justify">
                Perawatan untuk midsole sepatu yang menguning.
              </p>
            </div>
            <div className="md:col-span-2 md:col-start-3">
              <div className="flex justify-center items-center">
                <img className="w-full" src={whitening} alt="" />
              </div>
              <h1 className="text-2xl font-bold py-2">Whitening Treatment</h1>
              <p className="text-justify">
                Perawatan untuk memutihkan bagian upper sepatu.{" "}
              </p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default LayananMain;
