import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link } from "react-router-dom";
import {
  faDiscord,
  faFacebook,
  faFacebookSquare,
  faInstagram,
  faInstagramSquare,
  faLine,
  faLinkedin,
  faSteam,
  faTiktok,
  faTwitter,
  faGoogle,
  faTwitterSquare,
  faUssunnah,
  faWhatsapp,
  faYoutube,
  faMailchimp,
} from "@fortawesome/free-brands-svg-icons";
// import Logo from "../assets/logo-saujana-small.png";
// import Logo_ugm from "../assets/Lambang UGM-putih.png";
// import Logo_kkn from "../assets/Logo KKN UGM.png";
// import logo from "../assets/logo-saujana-big.png";

const footer = () => {
  return (
    <footer className=" bg-dark-blue z-100 pt-8 xl:py-0">
      <div className="flex flex-row xl:px-8">
        <div className="container mx-auto px-2 text-left sm:w-auto xl:w-full h-full">
          <div className="mx-4 flex justify-evenly gap-y-2 xl:flex-row xl:items-start flex-col gap-8 mobile:text-2xl">
            <div className="flex flex-col items-center self-center xl:items-start xl:self-auto">
              <div className="flex items-center sm:px-[15rem] xl:px-0 xl:gap-2 xl:flex-row mb-4 xl:pt-4">
                {/* <img
                  src={Logo_ugm}
                  alt=""
                  className="transition-all h-[100px] ml-3 xl:hidden xl:h-28"
                />
                <img
                  src={Logo_ugm}
                  alt=""
                  className=" hidden transition-all ml-3 xl:inline-flex xl:h-28"
                />
                <img
                  src={Logo_kkn}
                  alt=""
                  className=" hidden transition-all ml-3 xl:inline-flex xl:h-28"
                />
                <img
                  src={Logo_kkn}
                  alt=""
                  className="transition-all h-[100px] ml-3 xl:hidden xl:h-28 xl:scale-[1.4]"
                />
                <img
                  src={Logo}
                  alt=""
                  className="transition-all xl:hidden ml-3 xl:h-28"
                />
                <img
                  src={logo}
                  alt=""
                  className=" hidden transition-all xl:inline-flex xl:h-28"
                /> */}
              </div>
              <span className="">
                <p className="font-bold text-base md:text-lg text-center xl:text-left text-white lg:mt-6 xl:mt-0">
                  SHY SHOES
                </p>
                <p className="text-center xl:text-left text-sm md:text-base text-white lg:mt-6 xl:mt-0 mb-2">
                  CV. Hartara Drawana <br />
                  Padukuhan Siwil, Kalurahan Sukoharjo, Kapanewon Ngaglik,
                  Kabupaten Sleman
                </p>
              </span>
            </div>
            {/* quick link */}
            <div className="flex flex-col text-base md:text-lg text-white xl:gap-2 xl:text-xl gap-2 xl:my-6 my-3">
              <div className="flex flex-col gap-2">
                <Link
                  onClick={() => {
                    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
                  }}
                  to="/"
                  className="w-fit transition-colors hover:text-yellow-500"
                >
                  Beranda
                </Link>
                <Link
                  onClick={() => {
                    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
                  }}
                  to="/layanan"
                  className="w-fit transition-colors hover:text-yellow-500"
                >
                  Layanan
                </Link>
                <Link
                  onClick={() => {
                    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
                  }}
                  to="/tips"
                  className="w-fit transition-colors hover:text-yellow-500"
                >
                  Tips
                </Link>
                <Link
                  onClick={() => {
                    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
                  }}
                  to="/galeri"
                  className="w-fit transition-colors hover:text-yellow-500"
                >
                  Galeri
                </Link>
                {/* <Link
                  onClick={() => {
                    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
                  }}
                  to="/tps"
                  className="w-fit transition-colors hover:text-yellow-500"
                >
                  TPS
                </Link> */}
              </div>
            </div>
            {/* kontak person */}
            <div className="flex flex-col text-base xl:text-lg text-white gap-2 xl:my-6">
              <div className="flex flex-col gap-2">
                <p className="font-bold">Hubungi Kami</p>
                <div className="flex flex-row gap-10">
                  <a
                    href={"https://wa.me/6281228779089"}
                    rel="noreferrer"
                    target="_blank"
                    className="group w-fit transition-colors hover:text-yellow-500"
                  >
                    <FontAwesomeIcon
                      icon={faWhatsapp}
                      color="white"
                      size="1x"
                      className="transition-all  group-hover:text-yellow-500"
                    ></FontAwesomeIcon>{" "}
                    Whatsapp
                    <p>081228779089</p>
                  </a>
                  <a
                    href={"https://www.instagram.com/shyshoes.yk"}
                    rel="noreferrer"
                    target="_blank"
                    className="group w-fit transition-colors hover:text-yellow-500"
                  >
                    <FontAwesomeIcon
                      icon={faInstagram}
                      size="sm"
                      color="white"
                      className="transition-all group-hover:text-yellow-500"
                    ></FontAwesomeIcon>{" "}
                    Instagram
                    <p>@shyshoes.yk</p>
                  </a>
                </div>
                <div className="flex flex-row gap-10">
                  <a
                    href={"https://www.tiktok.com/@shyshoes.yk"}
                    rel="noreferrer"
                    target="_blank"
                    className="group w-fit transition-colors hover:text-yellow-500"
                  >
                    <FontAwesomeIcon
                      icon={faTiktok}
                      size="sm"
                      color="white"
                      className="transition-all group-hover:text-yellow-500"
                    ></FontAwesomeIcon>{" "}
                    Tiktok
                    <p>@shyshoes.yk</p>
                  </a>
                  <a
                    href={"mailto:shyshoes1.id@gmail.com"}
                    rel="noreferrer"
                    target="_blank"
                    className="group w-fit transition-colors hover:text-yellow-500"
                  >
                    <FontAwesomeIcon
                      icon={faGoogle}
                      size="sm"
                      color="white"
                      className="transition-all group-hover:text-yellow-500"
                    ></FontAwesomeIcon>{" "}
                    Email
                    <p>shyshoes1.id@gmail.com</p>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <p className="text-xs text-white bg-light-blue py-2 mt-3 xl:mt-0">
        &copy; Developed & Designed by Mufidus Sani
      </p>
    </footer>
  );
};

export default footer;
