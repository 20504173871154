import { Link } from "react-router-dom";

import TipsMain from "../components/tips/tips_main";
import { HtmlHead } from "../components/HtmlHead";
import GoogleAnalytics from "../components/googleads";

export default function Tips() {
  return (
    <>
      <HtmlHead
        title="Tips"
        decription="[insert page description]"
        link="/tips"
      />
      <main className="overflow-hidden">
        <GoogleAnalytics trackingId="G-EHY9VNJGZ8" />
        <TipsMain />
      </main>
    </>
  );
}
