import { useState } from "react";
import { Link } from "react-router-dom";
import sepatu_kotor from "../../assets/home/sepatu-homepage.png";
import AOS from "aos";
import "aos/dist/aos.css";

const Homepage = () => {
  AOS.init();
  AOS.refresh();
  AOS.refreshHard();
  return (
    <>
      <div className="bg-light-gray items-center justify-center flex pb-12 pt-28 xl:pb-10">
        <div className="flex flex-col xl:grid-rows-none xl:grid-cols-2 xl:grid px-6 xl:gap-12 xl:px-48 gap-4">
          <div className="xl:col-span-1 xl:row-span-full inline-block">
            <img
              data-aos="fade-down"
              data-aos-duration="1500"
              src={sepatu_kotor}
              alt=""
            />
          </div>
          <div
            data-aos="fade-up"
            data-aos-duration="1500"
            className="xl:row-span-full xl:col-span-1"
          >
            <h1 className="text-justify text-3xl font-bold xl:text-4xl font-Rubik">
              SHY SHOES
            </h1>
            <p className="text-justify xl:text-lg">Serahkan sepatumu kesini!</p>
          </div>
        </div>
      </div>
    </>
  );
  // AOS.init();
  // AOS.refresh();
  // AOS.refreshHard();
  // return (
  //   <>
  //     <div className="bg-center bg-cover h-screen opacity-60"></div>
  //     <div
  //       className="container absolute items-center w-auto top-1/2 px-12 text-[#374259] animate-[bounce_2s_infinite]"
  //       data-aos="fade-up"
  //       data-aos-duration="1500"
  //     >
  //       <p className="font-Montserrat text-left font-bold text-2xl mb-4 sm:text-2xl md:text-3xl lg:text-6xl bg-basic rounded-2xl p-3 bg-opacity-50">
  //         SELAMAT DATANG
  //         <br />
  //         DI DESA GIRIREJO
  //       </p>
  //       <p className="text-left sm:text-lg md:text-xl text-basic bg-light-blue bg-opacity-90 p-3 rounded-2xl">
  //         Jelajahi pesona alam dan hasil bumi Girirejo.
  //       </p>
  //     </div>
  //   </>
  // );
};

export default Homepage;
