import { useState } from "react";
import { Link } from "react-router-dom";
import foto1 from "../../assets/home/DSC09924.JPG";
import AOS from "aos";
import "aos/dist/aos.css";

const Tentang = () => {
  AOS.init();
  AOS.refresh();
  AOS.refreshHard();
  return (
    <>
      <div className="bg-dark-gray pt-12">
        <div className="flex flex-col xl:grid-rows-none xl:grid xl:grid-cols-2 px-6 xl:px-48">
          <div className="xl:col-span-1 xl:items-center xl:justify-center xl:flex">
            <img
              className="w-[400px]"
              data-aos="fade-right"
              data-aos-duration="1500"
              data-aos-offset="400"
              src={foto1}
              alt=""
            />
          </div>
          <div
            className="xl:col-span-1"
            data-aos="fade-left"
            data-aos-duration="1500"
            data-aos-offset="500"
          >
            <h1 className="my-4 xl:my-0 xl:mb-2 text-justify xl:text-3xl text-2xl font-bold font-Rubik">
              Tentang Kami
            </h1>
            <p className="text-justify xl:text-lg text-base">
              ShyShoes merupakan jasa pencucian dan perawatan yang berdiri sejak
              tahun 2022. ShyShoes berdiri di bawah naungan CV. Hartara Drawana
              yang berlokasi di Padukuhan Siwil, Kalurahan Sukoharjo, Kapanewon
              Ngaglik, Kabupaten Sleman. Saat ini, ShyShoes memiliki 5 drop
              point dan workshop yang tersebar di area sekitar UGM, UNY, UPN,
              dan Maguwoharjo. Kami menawarkan layanan berbasis media sosial
              sehingga pelanggan dapat dengan mudah untuk berkonsultasi,
              mencari, dan memilih layanan yang mereka butuhkan.
            </p>
          </div>
        </div>
      </div>
    </>
  );
};

export default Tentang;
