import { Link } from "react-router-dom";

import GaleriMain from "../components/galeri/galeri_main";
import { HtmlHead } from "../components/HtmlHead";
import GoogleAnalytics from "../components/googleads";

export default function Galeri() {
  return (
    <>
      <HtmlHead
        title="Galeri"
        decription="[insert page description]"
        link="/galeri"
      />
      <main className="overflow-hidden">
        <GoogleAnalytics trackingId="G-EHY9VNJGZ8" />
        <GaleriMain />
      </main>
    </>
  );
}
