import { Link } from "react-router-dom";

import Homepage from "../components/home/homepage";
import Tentang from "../components/home/tentang";
import Layanan from "../components/home/layanan";
import Value from "../components/home/value";
import { HtmlHead } from "../components/HtmlHead";
import GoogleAnalytics from "../components/googleads";

export default function Home() {
  return (
    <>
      <HtmlHead
        title="Beranda"
        decription="[insert page description]"
        link="/"
      />
      <main className="overflow-hidden">
        <GoogleAnalytics trackingId="G-EHY9VNJGZ8" />
        <Homepage />
        <Tentang />
        <Layanan />
        <Value />
      </main>
    </>
  );
}
